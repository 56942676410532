import skull from '../skull.png';

function PrivacyPolicy() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-sm-4 mb-4 offset-sm-4">
            <img class="img-fluid" src={skull} />
        </div>
      </div>
      <h1 class='font-falls white-text mb-3' >Privacy Policy</h1>

      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Privacy is important to us.
         </p>
      </h2>
      
         <p class="text-justify">
         CryptoMayor is committed to protecting all personally identifiable information you provide us through the CryptoMayor Service. CryptoMayor (hereinafter, "us", "we", or “The Service”) have created this Privacy Policy to communicate which information we collect during business operations, how we use this information, and when we may disclose the information to third parties.
         </p>
         
         <p class="text-justify">
         This document, and the Terms & Conditions posted on our Website, provide general rules and guidelines governing the use of our Service.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         The CryptoMayor Website
         </p>
      </h2>
      
         <p class="text-justify">
         The CryptoMayor website collects non-personally-identifying information that browsers make available, such as language preference, browser information, referring site, and the date and time of visitor requests. The purpose in collecting this information is to understand the Service’s usage patterns. CryptoMayor may use non-peronsally-identifying information to publish reports on trends in the usage of its website.
         </p>
         
         <p class="text-justify">
         CryptoMayor also collects information like IP addresses, that are potentially personally-identifying information, but only discloses logged in user IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Personally-Identifying Information
         </p>
      </h2>
      
         <p class="text-justify">
         CryptoMayor users may interact with the Service in ways that require us to obtain personally-identifying information. For example, users who sign up must provide a username and email address. We use this email address to fulfill password reset requests.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Security
         </p>
      </h2>
      
         <p class="text-justify">
         Keeping your Personal Information secure is critically important to us. We strive to use modern industry standard practices to protect your Personal data, but cannot guarantee its absolute security, since no method internet communication or storage is 100% secure.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Links To External Sites
         </p>
      </h2>
      
         <p class="text-justify">
         The Service contains links to external sites not operated by CryptoMayor. Clicking on these links will direct to the third party. We encourage you to review the Terms and Conditions and Privacy Policy of these sites.
         </p>
         
         <p class="text-justify">
         CryptoMayor does not have control over the policies or practices of third parties, and assumes no responsibility for these services.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Aggregated Statistics
         </p>
      </h2>
      
         <p class="text-justify">
         CryptoMayor collects metrics about the usage patterns of the Service. CryptoMayor may provide or display this information to others, however, we will not disclose your personally-identifying information.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Privacy Policy Changes
         </p>
      </h2>
      
         <p class="text-justify">
         CryptoMayor may change this Privacy Policy to accommodate the needs of the business. We encourage users to review this page frequently for changes to its Privacy Policy. Continuing to use the Service following policy changes constitutes your acceptance of these changes.
         </p>
      
         
      <h2 class='font-falls mt-5'>
         <p class="text-justify">
         Contact Information
         </p>
      </h2>
      
         <p class="text-justify">
         If you have any questions, please contact us via <a href="mailto:admin@cryptomayor.io">email</a>.
         </p>
      


    </div>
  );
}

export default PrivacyPolicy;
