import Token from './Token';
import Carousel from 'react-bootstrap/Carousel';

function TokenList(props) {
  const tokens = props.ids.map((id) =>
      <Carousel.Item style={{ height: "600px", backgroundImage: `url('http://worker-images.ws.pho.to/i1/933894B4-A474-43FF-974F-0DE6FDD24C68.jpg')` }} >
        <Token id={id} />
      </Carousel.Item>
  );
  return (
    <div class="container">
      <Carousel sytle={{ height: "800px"}}>
          { tokens }
      </Carousel>
    </div>
  );
}

export default TokenList;
