import skull from '../skull.png';

function Terms() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-sm-4 mb-4 offset-sm-4">
            <img class="img-fluid" src={skull} />
        </div>
      </div>
      <h1 class='font-falls white-text mb-3' >Terms of Service</h1>
      <h2 class='font-falls mt-3'>
        <p class='text-justify'>
        The Service
        </p>
      </h2>
      <p class='text-justify'>
CryptoMayor Tokens are Non-Fungible tokens (NFT) that exist on the distributed Cardano network. They leverage Cardano's Native NFTs which enable users to own and trade CryptoMayor tokens.
      </p>

      <p class='text-justify'>
CryptoMayor Inc. (“we”, “our”, or “us”) operate the CryptoMayor website (“the Service”), which provides art and metadata designed to be used with CryptoMayor NFTs. CryptoMayor tokens are NOT managed by the Service or by CryptoMayor Inc. Use NFTs at your own risk. You alone are responsible for the safety of your digital assets. We do not control the distributed blockchain or the digital marketplaces on which token transactions occur, and have no power to reverse transactions on the blockchain. We hold no responsibility for any taxes, duties, and assessments you may incur in digital asset transactions.
      </p>

      <h2 class='font-falls mt-3'>
        <p class='text-justify'>
          LICENSE & RESTRICTIONS
        </p>
      </h2>

      <p class='text-justify'>
CryptoMayor Inc. reserves all legal right, title, and interest to the materials provided by our service.  These materials include, but are not limited to the art and metadata associated with CryptoMayor tokens, other art displayed by the Service, our systems, data, logos, software, services, and organization. These materials do not include the CryptoMayor NFT tokens themselves, which are not owned by CryptoMayor Inc. By using this service, you agree that these materials are the property of CryptoMayor Inc. and that your use of the Service does not grant you rights or ownership of any of these materials. You understand that you do not have the right to distribute, reproduce, or sell materials owned by CryptoMayor Inc., except for the uses laid out below. You promise not to attempt to register or trademark any materials provided by CryptoMayor Inc.
      </p>

      <p class='text-justify'>
Subject to your adherence to these terms, CryptoMayor Inc. grants you non-transferable royalty-free permission to display the art created ONLY for the CyberMayor tokens owned by you, for non-commercial personal use, or on a marketplace which handles token transactions. To display our related materials, the marketplace must verify the ownership of of the CyberMayor NFT, and ensure that only you are able to display the related art.
      </p>

      <p class='text-justify'>
Subject to your adherence to these terms, CryptoMayor Inc. grants you a limited license to use, copy and display the materials related to your token for commercial use, as long as the total value is under $200,000 US dollars per year.
      </p>

      <p class='text-justify'>
You promise not to modify the materials related to your token in any way, or use the art to sell any third party product or service. You agree not to use the materials in conjunction with any materials which depict intolerance, violence, or hate of any kind. You promise not to use the materials in videos or film, except for non-commercial use. You agree that you may not attempt to distribute these materials for commercial gain.
      </p>

      <p class='text-justify'>
This limited license is granted only for the time in which you are the own the token. This license expires if the token leaves your possession for any reason, and you will no longer have any rights to the related materials.
      </p>

      <p class='text-justify'>
You grant us a permanent irrevocable worldwide license to use any feedback you provide to CryptoMayor Inc. without any compensation to you. You agree that we may share this feedback with our partners and third parties.
      </p>

      <p class='text-justify'>
You acknowledge that you are responsible for any consequences resulting from your interactions on with our service. You promise to use the Service only within what is allowed by regulation and law. You will not attempt to distribute viruses, worms, spyware, or otherwise malicious content while interacting with the Service. You will not attempt to distribute content which infringes on intellectual property rights of any other party. You will not attempt to reverse engineer or modify any part of the CryptoMayor Inc. software. You will not attempt to distribute content  through the Service which depicts intolerance, violence, or hate of any kind, or content that encourages others to do so. You will not create automated tools which retrieve or index any data provided by the Service.
      </p>

      <h2 class='font-falls mt-3'>
        <p class='text-justify'>
          TERMINATION
        </p>
      </h2>

      <p class='text-justify'>
You acknowledge that we may terminate your account, or remove the materials associated with your token at any time and for any reason. This may be done without any notice to you, and at our sole discretion. This will not impact the ownership rights to your CryptoMayor NFT tokens, which are not controlled by CryptoMayor Inc. If we terminate your account or related materials for reasons related to fraud, abuse, illegal activity, or a breach of these terms, the termination may be in addition to any legal action at our disposal. 
      </p>

      <p class='text-justify'>
You may terminate these terms at any time by ending your use of the Service. This will not impact the ownership rights to your CryptoMayor NFT tokens, which are not controlled by CryptoMayor Inc.
      </p>


      <p class='text-justify'>
      <h2 class='font-falls mt-3'>
DISCLAIMERS
      </h2>
      </p>

      <p class='text-justify'>
YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR SOLE RISK, AND THAT THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SERVICE AND ANY PART OF IT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SERVICE WILL BE ACCURATE, (III) THE SERVICE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SERVICE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
      </p>

      <p class='text-justify'>
YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
      </p>

      <p class='text-justify'>
WE ARE NOT RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES OR HARM YOU INCUR USING BLOCKCHAIN TECHNOLOGIES OR NFT MARKETPLACES LIKE OPENSEA. THIS INCLUDES BUT NOT LIMITED TO DAMAGES, LOSSES OR CLAIMS ARISING FROM: FORGOTTEN PASSWORDS, MISCONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS, SERVER FAILURE OR DATA LOSS, CORRUPTED WALLET FILES, UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE, ETHEREUM NETWORK, OR ELECTRONIC WALLETS.
      </p>

      <p class='text-justify'>
NFTS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF OWNERSHIP RECORDS IN THE ETHEREUM NETWORK. SMART CONTRACTS OCCUR ON THE DECENTRALIZED LEDGER ON THE ETHEREUM PLATFORM. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO THE BLOCKCHAIN OR SMART CONTRACTS.
      </p>

      <p class='text-justify'>
CRYPTOMAYOR INC. IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE ETHEREUM NETWORK OR ELECTRONIC WALLETS, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE ETHEREUM NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
      </p>

      <p class='text-justify'>
      <h2 class='font-falls mt-3'>
LIMITATION OF LIABILITY
      </h2>
      </p>

      <p class='text-justify'>
YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>

      <p class='text-justify'>
YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SERVICE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $100.
      </p>

      <p class='text-justify'>
YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SERVICE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE WOULD NOT BE ABLE TO PROVIDE THE SERVICE TO YOU WITHOUT THESE LIMITATIONS.
      </p>

      <p class='text-justify'>
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.
      </p>

      <p class='text-justify'>
      <h2 class='font-falls mt-3'>
RISK
      </h2>
      </p>
     


      <p class='text-justify'>
You acknowledge that digital assets are volatile and exhibit large price flucuations. These fluctuations could have a large impact on the value of your CryptoMayor NFT token.
      </p>

      <p class='text-justify'>
You acknowledge that there are risks with digital assets, including but not limited to internet connections, hardware, software, malicious code, where third parties could obtain unauthorized access to your assets. You accept that CryptoMayor Inc. is not responsible for any harm or loss you incur using these technologies.
      </p>

      <p class='text-justify'>
You acknowledge that business conditions may impact the development or operation of our service, which could impact the value of CryptoMayor NFT tokens.
      </p>

      <p class='text-justify'>
You understand that regulations may be passed which adversely impact the development and operation of our service and the value of CryptoMayor NFTs in a material way.
      </p>

      <p class='text-justify'>
You acknowledge that changes to the Cardano system, including but not limited to a hard-fork, or a change in how transactions are confirmed, may have adverse impacts on CryptoMayor Inc. and CryptoMayor NFTs.
      </p>

      <p class='text-justify'>
You promise to hold harmless and indemnify CryptoMayor Inc. and its employees, subsidiaries, affiliates, officers, agents, advertisers, licensors, suppliers or partners from and against any claim, loss, liability, damages of any kind or nature, suit, judgment, litigation cost, and attorneys' fees arising out of or in any way related to (i) A breach of these Terms by (ii) A misuse of the Service, or (iii) a violation of applicable laws, rules or regulations in connection with your access to or use of the Service. You agree that CryptoMayor Inc. will have control of the defense or settlement of any such claims.
     </p>

      <p class='text-justify'>
      <h2 class='font-falls mt-3'>
EXTERNAL SITES
      </h2>
      </p>

      <p class='text-justify'>
The Service may include links to third web sites or resources (collectively, “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites. You understand and agree that we are not responsible for the availability of any External Sites, and that we do not endorse any advertising, products or other materials on or made available from any External Sites. You acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites. You agree that you will not hold CryptoMayor Inc. responsible for any loss or damage you incur transacting NFTs on third party site links displayed by our service.
      </p>

      <p class='text-justify'>
We reserve the right to make changes to these terms. When we do, the updates will be made available through the Service and we will update the “Last Updated” date. You agree that it is your responsibility to check these terms periodically for changes. Any changes to the terms will be effective on the date they are changed. Your continued use of the Service constitutes a binding acceptance of the updated terms. If you can not agree to the updated terms, you must discontinue your use of the Service.
      </p>

      <p class='text-justify'>
To use this service, you must be over the age of 18. the Service is not intended for children. 
      </p>

      <p class='text-justify'>
Our Privacy Policy explains how we collect and store, and use data. In can be found on our website. By accepting these terms, you also agree to our privacy policy.
      </p>

      <p class='text-justify'>
In the interest of resolving disputes between you and CrypoMayor Inc. in the most expedient and cost effective manner, you and CryptoMayor Inc. agree that every dispute arising in connection with these Terms will be resolved by binding arbitration. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. Our agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND CRYPTOMAYOR INC. ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. Exceptions to this are claims in a small claims court, intellectual property claims, and enforcement actions.
      </p>

      <p class='text-justify'>
Arbitrator. Any arbitration between you and CryptoMayor Inc. will be governed by the Commercial Dispute Resolution Procedures and the Supplementary Procedures for Consumer Related Disputes (collectively, "AAA Rules") of the American Arbitration Association ("AAA"), as modified by these Terms, and will be administered by the AAA. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting CryptoMayor Inc.
      </p>

      <p class='text-justify'>
Fees. Any arbitration hearing will take place at a location decided by CryptoMayor Inc., but if the claim is for $1,000 or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse CryptoMayor Inc. for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.
      </p>

      <p class='text-justify'>
No Class Actions. YOU AND CRYPTOMAYOR INC. AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and CryptoMayor Inc. agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.
      </p>

      <p class='text-justify'>
These terms are the legal agreement between you and CryptoMayor Inc. These terms dictate your use of the Service and replace any prior agreement between the parties. Nothing in these terms creates an agency, partnership, or joint venture between you and CryptoMayor Inc. If any part of these terms are invalid, that part will be construed with applicable laws, and the rest of the terms will remain in effect. 
      </p>
    </div>
  );
}

export default Terms;
