import skull from '../skull.png';
import constants from '../constants.js'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { AiFillCopy } from 'react-icons/ai';

import React, { useState, useEffect } from 'react';

/*global fbq*/

function Cities(props) {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [pendingSearch, setPendingSearch] = useState();

  let timeout;

  const doSearch = (queryString) => {
    console.log('searching');
    fetch(
      `https://tokens.cryptomayor.io/tokens/`.concat(queryString),
    )
      .then(res => res.json())
      .then(response => {
        response.sort(function(a, b) { 
            return b.population - a.population;
        })
        setCities(response)
        setIsLoading(false);
      })
      .catch(error => console.log(error));

  }

  useEffect(() => {
    doSearch('')
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    if(pendingSearch) clearTimeout(pendingSearch);
    timeout = setTimeout(() => {
      console.log(event.target.value);
      doSearch(event.target.value)
    }, 1000);
   setPendingSearch(timeout);
  }


  return (
    <div class="container">
      <div class="row">
      <div class="col-md-4 offset-md-4 col-6 offset-3 mb-4">
          <img class="img-fluid" src={skull} />
      </div>
      </div>
      <h1 class='font-falls white-text mb-3'>Cities cost 35 ADA</h1>
      <input type='search' placeholder='Search' value={searchTerm} class="mb-3 form-control" onChange={ handleSearch } />

      {isLoading && <p>Loading Cities</p>}
      {!isLoading && (
        <div>
        <CityList cities={cities} date={props.date} />
        </div>
      )}
    </div>
  );
}

function CityList(props) {

    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState({});
    const [error, setError] = useState('');
    const [poller, setPoller] = useState('');

    const handleClose = () => {
        selected.closed = true
        setError('');
        setShow(false);
        clearInterval(poller);
    }
    const handleShow = (city) => {
        setSelected(city);
        selected.closed = false
        setShow(true);
        clearInterval(poller);
    }

    const reserve = (tokenId) => {
        setError("")
        fbq('track', 'AddToCart', {currency: "USD", value: 35.00});
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'foo': 'bar'})
        };
        fetch('https://tokens.cryptomayor.io/sales/'.concat(tokenId), requestOptions)
            .then(response => response.json())
            .then(data => { 
                if (data.error) {
                    setError(data.error)
                } else if (data.result == 'success') {
                    selected.reserved = true
                    selected.truePrice = data.price
                    setSelected({ ...selected});
                } else {
                    setError('An unknown error occured');
                }
            }).catch(error => {
                console.log(error);
            });
    }

    const checkStatus = (selected) => {
        const lovelacePrice = selected.truePrice * 10**6
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'query': `query {transactions(where: {outputs: {_and: [{address: {_eq:"${constants.payAddress}"},value: {_eq: "${lovelacePrice}"}}]}}){hash}}`})
        };
        fetch(constants.apiEndpoint, requestOptions)
            .then(response => response.json())
            .then(data => { 
                if (data.data && data.data.transactions && data.data.transactions.length > 0) {
                    fbq('track', 'Purchase', {currency: "USD", value: 35.00});
                    const salesTx = data.data.transactions[0].hash
                    selected.salesTx = salesTx;
                    setSelected({ ...selected});
                    //checkOwner(selected)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    const pollStatus = (selected) => {
        var interval = setInterval(function() { 
           console.log('internval');
           if (selected.closed || selected.salesTx) {
              console.log("clearing");
              clearInterval(poller);
              clearInterval(interval);
           }
           checkStatus(selected);
        }, 5000);
        setPoller(interval);
    }

    const checkOwner = (selected) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'query': `query {utxos(where: {tokens: {_and : [{ policyId: {_eq: "${constants.policyId}"}},{assetName:{_eq: "${selected.id}"}}]}}) {address}}`})
        };
        fetch(constants.apiEndpoint, requestOptions)
            .then(response => response.json())
            .then(data => { 
                console.log(data);
                selected.owner = data.price
                setSelected({ ...selected});
            }).catch(error => {
                console.log(error);
            });
    }

    const handleSubmit = (evt) => {
      evt.preventDefault();
      reserve(selected.id);
      pollStatus(selected, show);
      console.log("called pollstatus");
    }

    const cityStatus = {
        available: {
            label: 'available',
            labelClass: 'success',
        },
        pending: {
            label: 'pending',
            labelClass: 'warning',
        },
        sold: {
            label: 'sold',
            labelClass: 'secondary',
        }
    }


    props.cities.forEach(function(city) {
        city.fullName = city.name
        if (city.region1) {
            city.fullName = city.fullName.concat(", ", city.region1)
        }
        if (city.region2) {
            city.fullName = city.fullName.concat(", ", city.region2)
        }

        if (city.sold) {
            city.status = cityStatus.sold
        } else if (city.locked) {
            city.status = cityStatus.pending
        } else {
            city.status = cityStatus.available
        } 

    });

    const cityList = props.cities.map((city) => {
        let status;
        if (city.status.label == 'available')
          status = <a class="btn btn-sm btn-success font-falls">Buy Now</a>
        else {
          status = <span class={`badge badge-${city.status.labelClass}`} >{ city.status.label }</span>
        }
        return <tr onClick={ () => handleShow(city) }>
          <td>{city.country}</td>
          <th scope="row">{city.fullName}</th>
          <td>{status}</td>
        </tr>
    });

    let sale;
    if (selected.salesTx) {
          const receiptURL = "https://cardanoscan.io/transaction/".concat(selected.salesTx)
          sale = <div>
              <h3 class='text-center'>Your transaction has been <a target="_blank" href={receiptURL}>confirmed on the blockchain</a>!</h3>
              <p class='text-center'>You are now sole CryptoMayor of {selected.name}</p>
              <p class='text-center'>Please allow a few hours for your NFT to show up in your wallet</p>
          </div>
    } else if (selected.reserved) {
          sale = <div>
             <h3 class='text-success'>{selected.name} has been reserved for you!</h3>
             <p>Send the <b class='text-danger'>EXACT</b> amount of ADA below to the address listed.</p>
             <CopyToClipboard text={selected.truePrice}>
                 <div class="row mb-2">
                   <div class="col-10">
                     <div class="input-group mb-2 mr-sm-2">
                       <div class="input-group-prepend">
                         <div class="input-group-text"><b>ADA:</b></div>
                       </div>
                       <input type="text" class="form-control" value={selected.truePrice} readonly='readonly' />
                     </div>
                   </div>
                   <div class="col-2">
                     <a class="btn btn-primary"><AiFillCopy /></a>
                   </div>
                 </div>
             </CopyToClipboard>

             <CopyToClipboard text={constants.payAddress}>
                 <div class="row">
                   <div class="col-10">
                     <div class="input-group mb-2 mr-sm-2">
                       <div class="input-group-prepend">
                         <div class="input-group-text"><b>Adress:</b></div>
                       </div>
                       <input type="text" class="form-control" value={constants.payAddress} readonly='readonly' />
                     </div>
                   </div>
                   <div class="col-2">
                     <a class="btn btn-primary"><AiFillCopy /></a>
                   </div>
                 </div>
             </CopyToClipboard>
             <p>Make sure to use a Shelly-era Wallet or newer to send your ADA</p>
             <p>Your reservation expires in <b class='text-danger'>{constants.gracePeriod}</b>.</p>
             <p>This page will show a confirmation when your trasaction is confirmed. Email us at <a href="mailto:cyptomayor.io">admin@cryptomayor.io</a> if you have any questions.</p>
          </div>
    } else if (selected.sold) {
         sale = <p>This Token is already sold.</p>
    } else if (selected.locked) {
         sale = <p>This Token has already been reserved. Check back later. If the user does not pay for the token, it will go back up for sale.</p>
    } else {
         sale = <small id="addressHelp" class="form-text text-muted">To purchase, you'll need to download a Shelly-era wallet or newer and fund your wallet.</small>
    }

    const errorAlert = <div class="alert alert-danger" role="alert">
        { error }
    </div>

    const forSale = props.cities.filter(city => city.locked == false)
    const moreRecords = <div class="alert alert-warning">... List truncated. Filter using the search to discover more cities.</div>

    return (
        <div>
            <Modal size='xl' show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{selected.id}: {selected.fullName}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="row">
                  <div class="col-8 offset-2 col-lg-5 offset-lg-0">
                    <img class="img-fluid" src={ selected.image && `https://gateway.pinata.cloud/ipfs/${selected.image.substring(5)}`} />
                  </div>
                  <div class="col-lg-7">
                    { sale }
                    { error && errorAlert }
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                { !selected.reserved && !selected.locked && !selected.sold && <Button variant="warning" onClick={handleSubmit}><b>Buy</b></Button> }
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Country</th>
                  <th scope="col">City</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                  { cityList }
              </tbody>
            </table>
            { forSale.length >= 50 && moreRecords }
        </div>
    )
}

    

export default Cities;
