import { Link } from "react-router-dom";
import skull from '../skull.png';
import constants from '../constants.js';

function Places() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4 col-6 offset-3 mb-4">
            <img class="img-fluid" src={skull} />
        </div>
      </div>
      <h1 class='font-falls white-text mb-3' >peculiar places</h1>
      <h5 class='font-weight-light brown-text'>
        There are plenty of interesting gems and easter eggs in the geonames database that even we don't know about. This list is dedicated to some of the more peculiar ones. Reach out to us at <a href="mailto:cyptomayor.io">admin@cryptomayor.io</a> or on twitter if you acquire anything interesting.
      </h5>
      <h2 class='font-falls mt-5'>
        Marseille, France
      </h2>
      <h5 class='font-weight-light brown-text'>
        <img class='img-fluid' src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/MarseilleArrondissements10.png/270px-MarseilleArrondissements10.png" />
        <br />
        Marseille is split up into <a target="_blank" href="https://en.m.wikipedia.org/wiki/10th_arrondissement_of_Marseille">arrondissements</a>. As far as we know, it is the only place in the entire geonames database that is structured this way. If you get one of these 16 tokens, unite with your fellow Marseille collective. 
      </h5>


    </div>
  );
}

export default Places;
