const testnetAPI = 'https://graphql-api.testnet.dandelion.link'
const testPolicyId = '42d6cfd21627e093362c1c2ea8c1e8d5bb8e4da5ea9cf6c7eb95ee0'
const testPayAddress = 'addr_test1vrawmc26vgsq3rzl342d2elrqgee0q754yzsuwhs7m92qnc8cg0ct'




const mainnetAPI = 'https://graphql-api.mainnet.dandelion.link'
const mainnetPolicyId = '5e889bcb83b884bb6d768cfc483845cd6ccee79c2b5a4a15dae7ff47'
const mainnetPayAddress = 'addr1v9wtz2dsy2ejugtwm657yjf8kujlhpaft4x47uj9kg8lg9gzjvmpy'

const constants = {
    mintURL: "https://tba",
    forDate: "March 29th, 2021",
    payAddress: mainnetPayAddress,
    gracePeriod: "20 minutes", 
    yoroiWallet: "https://yoroi-wallet.com/#/",
    deadalusWallet: "https://daedaluswallet.io/",
    namiWallet: "https://namiwallet.io/",
    walletInstructionURL: "https://docs.cardano.org/en/latest/explainers/getting-started-with-cardano/how-to-purchase-ada.html",
    policyId: mainnetPolicyId,
    apiEndpoint: mainnetAPI,
};

export default constants;
