import { Link } from "react-router-dom";
import skull from '../skull.png';
import constants from '../constants.js';

function About() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4 col-6 offset-3 mb-4">
            <img class="img-fluid" src={skull} />
        </div>
      </div>
      <h1 class='font-falls white-text mb-3' >about cryptomayor</h1>
      <h2 class='font-falls mt-5'>
        What is an NFT?
      </h2>
      <h5 class='font-weight-light brown-text'>
        NFT stands for “non-fungible token”. It leverages the blockchain to create a record of ownership for digital assets. This ensures that there is exactly one CryptoMayor per city. This token cannot be duplicated, taken away, or destroyed. You can learn more about NFTs <a href="https://en.wikipedia.org/wiki/Non-fungible_token">here</a>.
      </h5>

      <h2 class='font-falls mt-5'>
        One token per city?
      </h2>

      <h5 class='font-weight-light brown-text'>
        That’s right. For example, there will only ever be ONE CryptoMayor of Los Angeles. After the token is minted, the only way to become CryptoMayor of Los Angeles is to acquire that token from the owner.
      </h5>

      <h2 class='font-falls mt-5'>
        Which cities will be minted?
      </h2>

      <h5 class='font-weight-light brown-text'>
        We want to get as close as possible to every city on earth. The list of cities is based on the <a target="_blank" href="https://www.geonames.org/">geonames</a> database. There are around 200,000 cities in the database. The CryptoMayor mint is capped at 200,000 tokens. 
        <br /><br />
        Cities are defined as being at least a forth order administrative division or having at least 500 residents. Wikipedia defines an administrative division as an "Area with a local government with a certain degree of autonomy, and is on a level below that of the sovereign state."<br /><br />
        The geonames database contains easter eggs that even we don't know about. Some entries seem more like neighborhoods or districts (for example "Queens" is a separate entry from "New York City"). We believe this makes the collection more interesting. If you find something unusual, reach out to us and we'll add it to our <Link to="/peculiar_places">list of peculiar places</Link>. You can download the full list of cities by visiting <a href="http://download.geonames.org/export/dump/">this link</a> and downloading the file cities500.zip. We do not curate this list. If it's printed, we mint it.
      </h5>

      <h2 class='font-falls mt-5'>
          How Can I become a CryptoMayor?
      </h2>

      <h5 class='font-weight-light brown-text'>
            Before you can purchase a token, you'll need a Shelly-era Cardano wallet, such as <a href={constants.deadalusWallet} target="_blank">Daedalus</a>, <a href={constants.yoroiWallet} target="_blank">Yoroi</a>, <a href={constants.namiWallet} target="_blank">Nami</a>, or CCVault and fund your wallet. These are ADA wallets which allows you to transact on the Cardano Blockchain. Please do not use an exchange wallet.
      </h5>
        <Link to="/cities" className="btn btn-warning font-falls">Buy Now</Link>

      <h2 class='font-falls mt-5'>
          Can I get a specific city?
      </h2>

      <h5 class='font-weight-light brown-text'>
          We plan to release all 200k cities in the near future. If you are a charity, we will consider donating a specific city to be auctioned for your cause (as long as that city has not already been minted). Email us at <a href="mailto:cyptomayor.io">admin@cryptomayor.io</a>    
      </h5>


      <h2 class='font-falls mt-5'>
        Can I build a game using CryptoMayor?
      </h2>

      <h5 class='font-weight-light brown-text'>
        Absolutely. We believe there are many interesting possibilities for CryptoMayor games. Each token's metadata includes statistics about the city which could make for interesting games. We’d love to collaborate. Reach out to us at <a href="mailto:cyptomayor.io">admin@cryptomayor.io</a>
      </h5>

      <h2 class='font-falls mt-5'>
        Where is the metadata stored?
      </h2>

      <h5 class='font-weight-light brown-text'>
        Metadata for each CryptoMayor token is stored immutably in the blockchain. This information is created alongside each token during the “mint” operation and can never be changed. The art for each token is stored permanently and immutably using <a href="https://www.arweave.org/" target="_blank">arweave</a>. Our website provides additional mutable data about each city such as the population (which changes over time) to help support CryptoMayor-based games, and allow CryptoMayors to customize how their city is displayed on the site.
      </h5>


      <h2 class='font-falls mt-5'>
        How can I validate that a CryptoMayor token is authentic?
      </h2>

      <h5 class='font-weight-light brown-text'>
        Cardano Tokens come in the form <code>PolicyID.TokenID</code>. All CryptoMayor tokens come from the PolicyID <code>{ constants.policyId }</code>. We are the only ones who can mint tokens with this policy. If the token does not have this policyID, it is <b>NOT</b> an authetic CryptoMayor token.
      </h5>


      <h2 class='font-falls mt-5'>
        Where does the Token ID come from?
      </h2>

      <h5 class='font-weight-light brown-text'>
        The ID of each token is based on the location id in the <a target="_blank" href="https://www.geonames.org/">geonames database</a>. You can discover more information about this location by visiting https://www.geonames.org/--TOKEN-ID-HERE--
      </h5>

      <h2 class='font-falls mt-5'>
        What about refunds?
      </h2>

      <h5 class='font-weight-light brown-text'>
        All sales are final. Please be careful to inspect your token to ensure you purchase the location you want. More details about each token can be found at https://www.geonames.org/--TOKEN-ID-HERE--
      </h5>


    </div>
  );
}

export default About;
