import { FaTwitter } from 'react-icons/fa';
import logo from './logo.svg';
import './App.css';
import skull from './skull.png';
import CryptoMayorsMap from './CryptoMayorsMap.jpeg';
import TokenList from './components/TokenList';
import About from './components/About';
import Terms from './components/Terms';
import City from './components/City';
import Cities from './components/Cities';
import Places from './components/Places';
import PrivacyPolicy from './components/PrivacyPolicy';
import constants from './constants.js';

import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";



function App() {


  return (
    <div className="App">
      <Router basename="/">
        <div>
          <nav class="container navbar navbar-expand navbar-light bg-transparent rounded">
            <a class="d-none d-sm-block navbar-brand font-falls" href="#">CryptoMayor</a>

           <ul class="navbar-nav mr-auto font-falls brown-text">
              <li class="nav-item">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li class="nav-item">
                <Link to="/cities" className="nav-link">Cities For Sale</Link>
              </li>
              <li class="nav-item">
                <Link to="/about" className="nav-link">FAQ</Link>
              </li>
              <li class="nav-item">
                <a target="_blank" href="https://twitter.com/cryptomayorNFT" className="nav-link"><FaTwitter /></a>
              </li>
            </ul>
          </nav>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/peculiar_places">
              <Places />
            </Route>
            <Route path="/cities">
              <Cities date={ constants.forDate } />
            </Route>
            <Route path="/city/:cityId">
              <City />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <div class="container text-center">
          <hr />
          <div class="row mb-3">
            <div class="col-lg-12">
              <ul class="nav nav-pills flex-column">
                <li>
                  <Link to="/" className="text-secondary">CryptoMayor</Link>
                </li>
                <li>
                  <Link to="/privacypolicy" className="text-secondary">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms" className="text-secondary">Terms of Service</Link>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          </div>
 
        </div>
      </Router>
    </div>
  );
}

function Home() {
  return (
    <div class="container">
      <h1 class='font-durango-western-eroded white-text' >CryptoMayor</h1>
      <h1 class='font-falls brown-text'>Own Your Town</h1>
      <div class="row">
        <div class="col-md-4 offset-md-4 col-6 offset-3 mb-4">
            <img class="img-fluid" src={skull} />
        </div>
      </div>
        <h4 class='brown-text font-weight-light'>
            CryptoMayor is a first of its kind NFT. There is exactly <b class='font-falls'>ONE</b> CryptoMayor token for every city on earth. The owner of that token is the CryptoMayor of the city. CryptoMayor is powered by the <a href='https://cardano.org/' target="_blank">Cardano</a> blockchain, which drastically reduces the network costs of buying and trading tokens.
        </h4>
        <Link to="/cities" className="btn btn-warning font-falls">Buy Now</Link>
        <h1 class='font-falls mt-5 white-text' >Inital Release</h1>
        <div class="row">
          <div class="col-md-4 offset-md-4 col-10 offset-1 mb-4">
            <img class="img-fluid" src="https://cryptomayor.s3.amazonaws.com/images/CryptoMayor5368361.png" />
          </div>
        </div>
        <h4 class='font-weight-light brown-text'>
          To date, we've released 10,000 cities. We're currently working on a wider release, which will include every city on earth. When every city is released, no more CryptoMayor tokens will ever be minted. Follow us on <a target="_blank" href="https://twitter.com/cryptomayorNFT">twitter <FaTwitter /></a> for updates.
        </h4>
        <h1 class='font-falls white-text mt-5' >How to become a CryptoMayor</h1>
        <div class="row">
          <div class="col-4 mb-4">
            <img class="img-fluid" src="https://cryptomayor.s3.amazonaws.com/images/beijing.png" />
          </div>
          <div class="col-4 mb-4">
            <img class="img-fluid" src="https://cryptomayor.s3.amazonaws.com/images/tokyo.png" />
          </div>
          <div class="col-4 mb-4">
            <img class="img-fluid" src="https://cryptomayor.s3.amazonaws.com/images/moscow.png" />
          </div>
        </div>
        <h4 class='font-falls brown-text'>Set up your ADA wallet</h4>
        <h4 class='font-weight-light brown-text'>
            Before you can purchase a token, you'll need a Shelly-era Cardano wallet, such as <a href={constants.deadalusWallet} target="_blank">Daedalus</a>, <a href={constants.yoroiWallet} target="_blank">Yoroi</a>, <a href={constants.namiWallet} target="_blank">Nami</a>, or CCVault and fund your wallet. These are ADA wallets which allow you to transact on the Cardano Blockchain. Please do not use an exchange wallet.
        </h4>
        <h4 class='font-weight-light brown-text'>
        Once your wallet is set up and funded, you're ready to buy.
        </h4>
        <Link to="/cities" className="btn btn-warning font-falls mb-4">Buy Now</Link><br />
        <div class="col-12 col-md-8 offset-md-2 embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/JSyWnRIcMfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <h1 class='font-falls white-text mt-5' >The CryptoMayor's Map</h1>
          <div class="col-md-6 offset-md-3 col-10 offset-1 mb-4">
            <a target="_blank" href="https://map.cryptomayor.io/"><img class="img-fluid" src={CryptoMayorsMap} /></a>
          </div>
        <h4 class='font-weight-light brown-text'>
            Check out <a target="_blank" href="https://map.cryptomayor.io/">The CryptoMayor's Map</a> to discover your neighbors.
        </h4>
        <h1 class='font-falls white-text mt-5' >Customize your City</h1>
        <h4 class='font-weight-light brown-text'>We're working on features which would allow the CryptoMayor to customize their city via signed messages. Unfortunately, ADA wallets don't support message signing yet.</h4>
    </div>
  );
}

function Topics() {
  return <h2>Topics</h2>;
}

export default App;
